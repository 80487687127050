const config = {
    social_link: {
        vkontakte: 'https://vk.com/share.php?url=',
        facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
        twitter: 'https://twitter.com/intent/tweet?url='
    },
    app_title: 'ЛК специалиста',
    app_url: '', // https://localhost:8080
    backend_url: 'https://academy.vitamax.ru/'/*'http://vitalearnapi/'*//*'https://wellenta.org/'*/,
    share: {
        fb_app_id: '',
        og_description: '',
    }
}
export default config