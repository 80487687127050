import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Lerning from './views/Learning'
import Learn from './views/Learn'
import Signin from './views/Signin'
import Hometask from './views/Hometask'
import Students from './views/Students'
import Course from './views/course'
import Diary from './views/Diary'
import Testing from './views/Testing'



Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/signin',
      name: 'signin',
      component: Signin
    },
    {
      path: '/signin/:id/:mail/:name/:secret/:idsp/:phone/',
      name: 'signin',
      component: Signin
    },
    {
      path: '/learning/',
      name: 'learning',
      component: Lerning
    },
    {
      path: '/learning/under/:course',
      name: 'learning',
      component: Lerning
    },
    {
      path: '/learning/:id',
      name: 'learn',
      component: Learn
    },
    {
      path: '/course/:id',
      name: 'course',
      component: Course
    },
    {
      path: '/students/',
      name: 'students',
      component: Students
    },
    {
      path: '/testing/:course',
      name: 'testing',
      component: Testing
    },
    {
      path: '/diary/',
      name: 'diary',
      component: Diary
    },
    {
      path: '/hometask/:id/:lect',
      name: 'hometask',
      component: Hometask
    },
    {
      path: '/setmark/:id/:lect/:student',
      name: 'setmark',
      component: Hometask
    },
    {
      path: '*',
      redirect: '/'
    },
  ],
  mode: 'hash'
})
