<template>
    <header class="vm-header">
        <div class="vm-container">
            <a class="vm-header__logo-link" @click="goTo" data-to="/">
                <span class="vm-header__logo" @click="goTo" data-to="/"><img @click="goTo" data-to="/" src="/assets/images/logo.svg" alt="logo"/></span>
                <span class="vm-header__title" @click="goTo" data-to="/">МЕЖДУНАРОДНАЯ АКАДЕМИЯ ВЕЛНЕС И ПРЕДПРИНИМАТЕЛЬСТВА</span>
            </a>
        </div>
    </header>
</template>

<script>


    export default {
        name: "Header",
        data() {
            return {

            }
        },
        methods: {
            goTo(e) {
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            }
        },
        created() {
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>