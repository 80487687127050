<template>
    <div class="body">
        <Header></Header>
        <section class="vm-body vm-body__index1">

            <div class="vm-container">

                <div class="vm-link-back-box">
                    <a class="vm-link-back" href="index.html"></a>
                    <span class="vm-link-back__title">{{user.NAME}}</span>
                </div>

                <div class="vm-hallo__title">МОЙ ДНЕВНИК</div>

                <div v-if="load" class="vm-shoulder">
                    <div class="vm-shoulder__item">
                        <div class="vm-shoulder__select">
                            <label>Выберите курс</label>
                            <select v-model="selectedCourse" @click="getHts">
                                <option v-for="(course, k) in user.PROPERTY_303" v-bind:key="k" :value="course">
                                    {{courseName(course)}}
                                </option>

                            </select>
                        </div>
                    </div>
                </div>

                <div class="vm-shoulder__title" v-if="selectedCourse">Прогресс по курсу {{courseName(selectedCourse)}}</div>

                <!--                <div class="vm-shoulder__table-info">-->
                <!--                    <div>Просмотрено 6 лекций из 10 </div>-->
                <!--                </div>-->

                <table class="vm-shoulder__table">
                    <thead>
                    <tr>
                        <th>Курс</th>
                        <th>Лекция</th>
                        <th>Дата</th>
                        <th><i class="vm-icon vm-icon-20"></i></th>
                        <th>Задание</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :class="'active'" v-for="(ht, k) in hts" v-bind:key="k"
                        v-show="ht.PROPERTY_315">
                        <td :style="(ht.PROPERTY_413)? '' : 'font-weight: bold;'">{{courseName(ht.PROPERTY_311)}}</td>
                        <td :style="(ht.PROPERTY_413)? '' : 'font-weight: bold;'">{{lectNames[ht.PROPERTY_313].NAME}}</td>
                        <td :style="(ht.PROPERTY_413)? '' : 'font-weight: bold;'">{{ht.PROPERTY_325}}</td>
                        <td :style="(ht.PROPERTY_413)? '' : 'font-weight: bold;'">{{ht.PROPERTY_415}}</td>
                        <td :style="(ht.PROPERTY_413)? '' : 'font-weight: bold;'">
                            <a @click="goTo"
                               :data-to="'/hometask/'+ht.PROPERTY_311+'/'+ht.PROPERTY_313+'/'">Открыть</a>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Navigation active="diary"></Navigation>

            </div>

        </section>
        <Footer></Footer>
    </div>
</template>

<script>
    import config from '../config';
    import Header from './header.vue';
    import Footer from './footer.vue';
    import Navigation from './navigation.vue';

    export default {
        name: "Diary",
        components: {
            Header,
            Footer,
            Navigation
        },
        data() {
            return {
                url: config.backend_url,
                user: this.$store.getters.userInfo,
                hts: [],
                selectedCourse: (this.$cookies.get("courseSelected"))? this.$cookies.get("courseSelected") : null,
                courses: [],
                lectNames: {},
                load: false
            }
        },
        methods: {
            goTo(e) {
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            },
            getHts() {
                let self = this;
                if (this.selectedCourse) {
                    this.axios.get(this.url + "BX24/getStudentHT/?id=" + this.user.ID + '&course=' + this.selectedCourse).then((response) => {
                        self.hts = response.data;
                        this.$cookies.set("courseSelected", self.selectedCourse, 36000000000);
                    })
                }
            },
            isActive(lect) {
                let k = 0;
                for (let i = 0; i < this.hts.length; i++) {
                    if (this.hts[i].PROPERTY_313 == lect) {
                        k++;
                        if (k > 1) {
                            return true;
                        }
                    }
                }
                return false;
            },
            getCourses() {
                let self = this;
                this.axios.get(this.url + "BX24/getCourses/").then((response) => {
                    self.courses = response.data;
                    setTimeout(() => self.load = true, 500);
                })
            },
            courseName(course){
                if (course != "0") {
                    let courseName = this.courses.find(cours => cours.ID === course).NAME;
                    return courseName;
                }
            },
            getLects(){
                let self = this;
                this.axios.get(this.url + "BX24/getLect/").then((response) => {
                    self.lectNames = response.data;
                });
            }
        },
        created() {
            // this.getHts();
            this.getCourses();
            this.getLects();
            if (this.selectedCourse) {
                this.getHts()
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>