<template>
    <div class="body">
        <Header></Header>
        <!--        <hexagon v-if="!showAll" style="position: absolute;top: 50vh;left: 50vw;z-index: 999;"></hexagon>-->
        <section class="vm-body vm-body__index1">
            <div class="vm-container">
                <div class="vm-link-back-box">
                    <a class="vm-link-back" href="index.html"></a>
                    <span class="vm-link-back__title">{{user.NAME}}</span>
                </div>

                <div class="vm-home-work__header">
                    <div class="vm-home-work__header-item">
                        <div class="vm-home-work__header-info"><i class="vm-icon vm-icon-1"></i>Курс: {{courses.NAME}}
                        </div>
                        <div class="vm-home-work__header-info"><i class="vm-icon vm-icon-21"></i>{{courseContent.NAME}}
                        </div>
                        <div class="vm-home-work__header-info"><i class="vm-icon vm-icon-7"></i>Курс читает<br/>{{courses.PROPERTY_343}}
                        </div>
                    </div>
                    <div class="vm-home-work__header-item">
                        <div class="vm-home-work__header-info"><i class="vm-icon vm-icon-7"></i>Ваш наставник<br/>{{master.NAME}}
                        </div>
                    </div>
                </div>
                <div v-if="!loading && test[currentQuestion]">
                    <div> Вопрос {{currentQuestion + 1}} из {{courseContent.TESTS}}</div>
                    <div v-html="test[currentQuestion].PROPERTY_456[0].TEXT"></div>
                    <div>Варианты ответа</div>
                    <div  v-for="(option, i) in test[currentQuestion].PROPERTY_458" v-bind:key="i+'452453245'">
                        <div><input style="cursor:pointer;" type="checkbox" :id="i" v-model="currentChoosed[i]"><label
                                :for="i" style="cursor:pointer;">{{option}}</label></div>
                    </div>
                    <br>
                    <div class="vm-btn" style="cursor: pointer;" v-if="currentQuestion < test.length-1 && currentChoosed.length > 0" @click="nextQst">Следущий вопрос</div>
                    <div class="vm-btn" style="cursor: pointer;" v-if="currentQuestion >= test.length-1 && currentChoosed.length > 0" @click="check">Завершить тестирование</div>
                </div>
                <div v-if="!test[currentQuestion]">
                    Результат: {{result}} % <br>
                    <span v-if="result >= 70">Подзравляем, у вас есть сертификат по курсу {{courses.NAME}}!</span>
                    <span v-else>Вам надо немного поднять знания по курсу {{courses.NAME}}.</span>
                </div>
                <div v-if="!test[currentQuestion]">
                    <div class="vm-btn" @click="goTo" data-to="/learning/"> Вернуться к курсам</div>
                </div>
                <Navigation active="learn"></Navigation>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script>
    import config from '../config';
    import Header from './header.vue';
    import Footer from './footer.vue';
    import Navigation from './navigation.vue';
    // import {Hexagon} from 'vue-loading-spinner'

    export default {
        name: "Testing",
        components: {
            // Hexagon,
            Header,
            Footer,
            Navigation
        },
        data() {
            return {
                loading: true,
                currentQuestion: 0,
                currentChoosed: [],
                results: [],
                result: null,
                url: config.backend_url,
                user: this.$store.getters.userInfo,
                courseContent: [],
                courses: [],
                ht: [],
                test: [],
                sended: false,
                mark: null,
                master: {},
                showAll: false,
                changedAnswer: false
            }
        },
        methods: {
            check() {
                this.results.push(this.currentChoosed);
                this.currentQuestion++;
                this.currentChoosed = [];
                let totalQst = this.courseContent.TESTS;
                let self = this;
                let qst = 0;
                let correctAns = 0;
                this.results.map(function (answer) {
                    let localright = 0;
                    self.test[qst].PROPERTY_459.map(function (correct) {
                        window.console.log(correct);
                        window.console.log(answer[correct - 1]);
                        if (answer[correct - 1]) {
                            localright++;
                        }
                    });
                    window.console.log(self.test[qst].PROPERTY_459.length);
                    if (localright == self.test[qst].PROPERTY_459.length) {
                        correctAns++
                    }
                    qst++;
                });
                this.result = Math.round(correctAns / totalQst * 100);
                if (this.result >= 70) {
                    this.axios.get(this.url + "BX24/setUserInfo/?id=" + this.user.PROPERTY_249 + "&prop_PROPERTY_305=" + this.$route.params.course + "&prop_PROPERTY_257=" + this.result).then(() =>
                        self.axios.get(self.url + "BX24/getNPV/?id=" + self.user.PROPERTY_249).then((response) => {
                            if (response.data) {
                                self.$store.dispatch("SET_USERINFO", response.data);
                                self.user = self.$store.getters.userInfo;
                                self.$router.push("/learning/");
                            } else {
                                alert("Данного пользователя нет в системе");
                            }
                        }));
                }
                let data = {
                    prop_NAME: this.user.NAME,
                    prop_PROPERTY_463: this.$route.params.course, //курс
                    prop_PROPERTY_461: this.user.ID, //ИД ученика
                    prop_PROPERTY_465: (this.result >= 70) ? "7751" : "7750", // тип действия
                    prop_PROPERTY_466: this.result
                }
                this.axios.post(this.url + "BX24/setCertificate/", data).then(() => {
                    self.sended = true;
                });
            },
            nextQst() {
                this.results.push(this.currentChoosed);
                this.currentQuestion++;
                this.currentChoosed = [];
            },
            inputAnswer() {
                this.changedAnswer = true;
            },
            back() {
                window.history.back()
            },
            goTo(e) {
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            },
            getCourses() {
                let self = this;
                let admin = (this.user.PROPERTY_433) ? this.user.PROPERTY_433 : "";
                this.axios.get(this.url + "BX24/getCourses/?id=" + this.$route.params.course + "&type=noFile" + "&admin=" + admin).then((response) => {
                    self.courses = response.data[0];
                })
            },
            getCourse() {
                let self = this;
                this.axios.get(this.url + "BX24/getCourse/?id=" + this.$route.params.course + "&type=noFile").then((response) => {
                    self.courseContent = response.data[0];
                });
            },
            getTest() {
                let self = this;
                this.axios.get(this.url + "BX24/getTest/?courseId=" + this.$route.params.course).then((response) => {
                    self.test = response.data;
                    self.loading = false;
                });
            },
            getMaster() {
                let self = this;
                this.axios.get(this.url + "BX24/getNPV/?idFace=" + this.user.PROPERTY_273).then((response) => {
                    if (response.data) {
                        self.master = response.data;
                    }
                })
            },
            sendAnswer() {
                if (this.changedAnswer && !this.sended) {
                    if ((this.$route.name == "setmark" && this.answer.textCurator && this.mark) || this.$route.name == "hometask") {
                        this.sended = true;
                        let self = this;
                        let data = {
                            prop_NAME: this.user.NAME,
                            prop_PROPERTY_311: this.$route.params.id, //курс
                            prop_PROPERTY_313: this.$route.params.lect, // лекция
                            prop_PROPERTY_315: this.answer.textAnswer, // ответ ученика
                            prop_PROPERTY_317: this.answer.textCurator, // ответ куратора
                            prop_PROPERTY_383: (this.$route.name == "setmark") ? this.$route.params.student : this.user.ID, // ид ученика
                            prop_PROPERTY_327: (this.answer.textCurator) ? "171" : "137", // тип действия
                        }
                        this.axios.post(this.url + "BX24/setHomeTask/", data).then(() => {
                            self.getHt(false);
                        });
                        if (this.answer.textCurator) {
                            this.axios.post(this.url + "BX24/setCheckedHt/?id=" + this.ht[0].ID + "&mark=" + this.mark)
                        }
                    } else {
                        alert("Поставьте оценку и введите свой комментарий.")
                    }
                } else {
                    alert("Вы не ввели свой ответ или комментарий");
                }
            },
        },
        created() {
            this.getCourse();
            this.getCourses();
            this.getMaster();
            this.getTest();
            this.getCourse();
            // setTimeout(() => this.showAll=true, 2000);
        },
        mounted() {
            // this.getCourse();


        }
    }
</script>

<style scoped>

</style>