<template>
    <footer class="vm-footer">
        <div class="vm-container">
            <div class="vm-footer__socials">
<!--                <a href="#"><i class="vm-icon vm-icon-11"></i></a>-->
<!--                <a href="#"><i class="vm-icon vm-icon-12"></i></a>-->
<!--                <a href="#"><i class="vm-icon vm-icon-13"></i></a>-->
<!--                <a href="#"><i class="vm-icon vm-icon-14"></i></a>-->
            </div>
            <div class="vm-footer__logos">
<!--                <a href="#"><i class="vm-icon vm-icon-10"></i></a>-->
            </div>
        </div>
    </footer>
</template>

<script>

    export default {
        name: "Footer",
        data() {
            return {

            }
        },
        methods: {
            goTo(e) {
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            },
        },
        created() {
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>