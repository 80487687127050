<template>
    <div class="body">
        <hexagon v-if="!showAll" style="position: absolute;top: 50vh;left: 50vw;z-index: 999;"></hexagon>
        <Header></Header>
        <section v-if="showAll" class="vm-body vm-body__index1-2">

            <div class="vm-container">

                <div class="vm-link-back-box">
                    <a class="vm-link-back" @click="goTo" data-to="/learning"></a>
                    <span class="vm-link-back__title">{{user.NAME}}</span>
                </div>

                <div class="vm-lession">

                    <div class="vm-lession__listing">
                        <div class="vm-lession__listing-header" v-if="courseData[0]">
                            <img :src="courseData[0].PROPERTY_417[0]" alt="logo"/>
                            <span>{{courseData[0].PROPERTY_427}}</span>
                        </div>
                        <div class="vm-lession__list">
                            <ol>
                                <li @click="getVideo" :data-watch="courseContent[k].PROPERTY_229"
                                    v-for="(lect, k) in courseContent"
                                    :data-lect="k" v-bind:key="k"
                                    :class="((currentLect==k)? 'active ':'')+((watchedLects < k)? ' disactive':'')"
                                    :title="(watchedLects < k)? 'Просмотрите предыдущие лекции':''"
                                >{{k+1}}. {{lect.NAME}} <i v-if="readyHt(lect.ID)" style="font-size: 12px;" class="vm-icon vm-icon-28"></i>
                                </li>
                            </ol>
                            <a class="vm-curators__link-arrow" href="#">&nbsp;</a>
                        </div>
                    </div>

                    <div class="vm-lession__title" v-html="courseContent[currentLect].NAME"></div>

                    <div class="vm-video-box">
                        <video-player class="video-player-box"
                                      ref="videoPlayer"
                                      :options="playerOptions"
                                      :playsinline="true"
                                      @ended="onPlayerEnded()"
                        >
                        </video-player>
                    </div>

                    <div class="vm-lession__docs">
                        <div class="vm-lession__docs-list">
                            <div class="vm-lession__docs-header">материалы к лекции</div>
                            <div class="vm-lession__docs-item"
                                 v-for="(doc, k) in courseContent[currentLect].PROPERTY_231" v-bind:key="k">
                                <div>
                                    <img src="/assets/images/list.svg" alt="img"/>
                                    <span>{{courseContent[currentLect].PROPERTY_339[k]}} </span>
                                </div>
                                <div style="margin-left: auto" v-if="courseContent[currentLect].PROPERTY_339[k]">
                                    <a v-if="courseContent[currentLect].PROPERTY_339[k].includes('Видео')"><i data-type="extra" :data-lect="currentLect" @click="getVideo" :data-watch="courseContent[currentLect].PROPERTY_231[k]" class="vm-icon vm-icon-25"></i></a>

                                    <a v-if="!courseContent[currentLect].PROPERTY_339[k].includes('Видео')" :href="courseContent[currentLect].PROPERTY_231[k]" download><i
                                            class="vm-icon vm-icon-26"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="vm-lession__docs-btns" v-if="courseContent.PROPERTY_289">
                            <a class="vm-btn"
                               :data-to="'/hometask/'+$route.params.id+'/'+courseContent[currentLect].ID+'/'"
                               @click="goTo">перейти к домашнему ЗАДАНИю</a>
                            <a v-if="currentLect < watchedLects" class="vm-btn" :data-lect="parseInt(currentLect)+1" @click="getVideo" :data-watch="courseContent[parseInt(currentLect)+1].PROPERTY_229">Перейти к следующей лекции</a>
                            <a v-if="currentLect == courseContent.length-1 && !finished && !courseContent[0].HASTEST" class="vm-btn"  @click="finishCourse">Завершить курс</a>
                            <a v-if="currentLect == courseContent.length+1 && !finished && courseContent[0].HASTEST && currentLect < watchedLects" class="vm-btn">Пройти тестирование</a>
                            <img class="desc-only-show" src="/assets/images/logo.svg" alt="logo"/>
                        </div>
                        <div class="vm-lession__docs-btns" v-else>
                            <a :data-to="'/testing/'+$route.params.id" @click="goTo" v-if="currentLect == courseContent.length-1 && !finished && courseContent[0].HASTEST && endVideo" class="vm-btn">Пройти тестирование</a>
                            <a v-if="currentLect < watchedLects" class="vm-btn" :data-lect="parseInt(currentLect)+1" @click="getVideo" :data-watch="courseContent[parseInt(currentLect)+1].PROPERTY_229">Перейти к следующей лекции</a>
                            <img class="desc-only-show" src="/assets/images/logo.svg" alt="logo"/>
                        </div>
                    </div>


                </div>

                <Navigation active="learn"></Navigation>

            </div>

        </section>

        <Footer v-if="showAll"></Footer>
        <div style="display: none">{{change}}</div>
    </div>
</template>

<script>
    import config from '../config';
    import {Hexagon} from 'vue-loading-spinner'
    // import FileUpload from 'vue-simple-upload/dist/FileUpload'
    import Header from './header.vue';
    import Footer from './footer.vue';
    import Navigation from './navigation.vue';

    export default {
        components: {
            Hexagon,
            // 'fileupload': FileUpload,
            Header,
            Footer,
            Navigation
        },
        name: "Learn",
        data() {
            return {
                endVideo: false,
                change: 0,
                courseData: {},
                showPdf: null,
                comment: null,
                showMenu: false,
                user: this.$store.getters.userInfo,
                url: config.backend_url,
                course: this.$route.params.id,
                courseContent: [],
                watchedLects: 0,
                showVideo: false,
                showNext: false,
                marks: null,
                mark: null,
                htAnswer: null,
                htShow: true,
                htSended: false,
                finished: false,
                hts:[],
                markDescr: ["Пожалуйста, напишите, что Вам понравилось", "Пожалуйста, напишите, что Вам понравилось", "Пожалуйста, напишите, что Вам не понравилось и что можно улучшить"],
                playerOptions: {
                    // videojs options
                    muted: false,
                    language: 'en',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    sources: [
                        // {
                        //     type: "video/mp4",
                        //     src: ""
                        // }
                    ],
                    poster: null,
                },
                showAll: false,
                showMessage: false,
                currentLect: 0,
                chooseVideo:0
            }
        },
        methods: {
            progress(e) {
                // file upload progress
                // returns false if progress is not computable
                window.console.log(e);
            },
            onPlayerEnded() {
                this.endVideo = true;
                this.showNext = true;
                this.htShow = true;
                let data = {
                    prop_NAME: this.user.NAME,
                    prop_PROPERTY_311: this.$route.params.id, //курс
                    prop_PROPERTY_313: this.courseContent[this.currentLect].ID, // лекция
                    prop_PROPERTY_383: this.user.ID, // ид ученика
                    prop_PROPERTY_327: "135", // тип действия просмотр лекции
                };
                this.axios.post(this.url + "BX24/setHomeTask/", data);
                if (this.currentLect < this.courseContent.length -1) {
                    this.next();
                }
            },
            getVideo(e) {
                let self = this;
                let lect = e.srcElement.dataset.lect;
                if (this.watchedLects >= parseInt(lect)) {
                    // this.showVideo = false;
                    let src = e.srcElement.dataset.watch;
                    if (src.length < 9) {
                        this.axios.get(this.url + "BX24/getVideo/?file=" + src).then((response) => {
                            self.$set(self.playerOptions.sources, self.chooseVideo, {
                                type: "video/mp4",
                                src: response.data.src,
                            });
                            setTimeout(() => self.showVideo = true, 1000);
                            setTimeout(() => self.showAll = true, 1000);
                        })
                    } else {
                        this.$set(this.playerOptions.sources, this.chooseVideo, {
                            type: "video/mp4",
                            src: src
                        });
                    }
                    this.currentLect = parseInt(lect);
                    this.showMenu = false;
                    // this.chooseVideo++;
                    setTimeout(() => this.showVideo = true, 300);
                    setTimeout(() => this.htSended = false, 300);
                    setTimeout(() => this.htShow = false, 300);
                    setTimeout(() => this.getFiles(lect), 1000);
                    setTimeout(() =>this.player.play(), 1000);
                }
            },
            setFirstVideo() {
                this.$set(this.playerOptions.sources, this.chooseVideo, {
                    type: "video/mp4",
                    src: this.courseContent[this.currentLect].PROPERTY_229[0]
                });
                // this.chooseVideo++;
                // this.showVideo = true;
            },
            goTo(e) {
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            },
            getCourse() {
                if (!this.courseContent[0]) {
                    let self = this;
                    let lect = 0;
                    if (this.user.PROPERTY_303){
                        lect =this.user.PROPERTY_303.indexOf(this.course);
                    }
                    this.currentLect = (this.user.PROPERTY_259)? Number(this.user.PROPERTY_259[lect]) : 0;
                    this.watchedLects = (this.user.PROPERTY_259)? Number(this.user.PROPERTY_259[lect]) : 0;
                    this.axios.get(this.url + "BX24/getCourse/?id=" + this.course + "&lect=" + this.currentLect).then((response) => {
                        self.courseContent = response.data;
                        self.setFirstVideo();
                        setTimeout(() => self.showAll = true, 1000);
                        setTimeout(() => self.getFiles(self.currentLect), 1000);
                    });
                    let admin = (this.user.PROPERTY_433)? this.user.PROPERTY_433 : "";
                    this.axios.get(this.url + "BX24/getCourses/?id=" + this.course+"&admin="+admin).then((response) => {
                        self.courseData = response.data;
                        self.playerOptions.poster =  self.courseData[0].PROPERTY_283[0];
                    });
                    this.axios.get(this.url + "BX24/getStudentHT/?id=" + this.user.ID + '&course=' + this.$route.params.id).then((response) => {
                        self.hts = response.data;
                    });
                    if (this.user.PROPERTY_305) {
                        let idCourse = this.user.PROPERTY_305.indexOf(this.$route.params.id);
                        if (idCourse != -1) {
                            this.finished = true;
                        }
                    }
                }
            },
            getFiles(lect) {
                if (this.courseContent[lect].PROPERTY_231) {
                    if (this.courseContent[lect].PROPERTY_231[0].length < 9) {
                        let self = this;
                        this.courseContent[lect].PROPERTY_231.forEach(function (item, i) {
                            self.axios.get(self.url + "BX24/getVideo/?file=" + item).then((response) => {
                                self.courseContent[lect].PROPERTY_231[i] = response.data.src;
                                self.change++;
                            });
                        });
                    }
                }
            },
            next() {
                // this.showVideo = false;
                let self = this;
                this.endVideo = false;
                this.showNext = false;
                // this.currentLect++;
                if (this.currentLect == this.watchedLects) {
                    this.watchedLects++;
                    this.axios.get(this.url + "BX24/setUserInfo/?id=" + this.user.PROPERTY_249 + "&prop_PROPERTY_259=" + this.watchedLects + '&prop_PROPERTY_303=' + this.course);
                    setTimeout(() => this.axios.get(this.url + "BX24/getNPV/?id=" + this.user.PROPERTY_249).then((response) => {
                        if (response.data) {
                            self.$store.dispatch("SET_USERINFO", response.data);
                            self.user = this.$store.getters.userInfo;
                        } else {
                            alert("Данного пользователя нет в системе");
                        }
                    }), 1000);
                }
            },
            finishCourse() {
                let self = this;
                this.axios.get(this.url + "BX24/setUserInfo/?id=" + this.user.PROPERTY_249 + "&prop_PROPERTY_305=" + this.$route.params.id + "&prop_PROPERTY_257=0").then(() =>
                    self.axios.get(this.url + "BX24/getNPV/?id=" + this.user.PROPERTY_249).then((response) => {
                        if (response.data) {
                            self.$store.dispatch("SET_USERINFO", response.data);
                            self.user = self.$store.getters.userInfo;
                            self.$router.push("/learning/");
                        } else {
                            alert("Данного пользователя нет в системе");
                        }
                    }));
            },
            readyHt(lect){
                let result = false;
                this.hts.map(function(ht) {
                    if (ht.PROPERTY_313 == lect){
                        result = true;
                    }
                })
                return result;
            }
        },
        created() {
            let self = this;
            setTimeout(() => this.axios.get(this.url + "BX24/getNPV/?id=" + this.user.PROPERTY_249).then((response) => {
                if (response.data) {
                    self.$store.dispatch("SET_USERINFO", response.data);
                    self.user = self.$store.getters.userInfo;
                    setTimeout(() => self.getCourse(), 1000);

                } else {
                    alert("Данного пользователя нет в системе");
                }
            }), 1000);
            // this.getUserById(this.$store.getters.userId);
            // setTimeout(() => this.getManager(), 1000);
        },
        mounted() {
            // this.user = this.$store.getters.userInfo;
            // window.console.log(this.user);
            // setTimeout(() => this.getVideoById(this.courseContent[this.currentLect].), 5000);
        },
        computed: {
            player() {
                return this.$refs.videoPlayer.player
            }
        }
    }
</script>

<style scoped>

</style>