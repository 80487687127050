<template>
    <div class="body">
        <Header></Header>


        <section class="vm-body vm-body__index1">

            <div class="vm-container" v-if="show">
                <div class="vm-link-back-box">
                    <a class="vm-link-back" data-to="learning" @click="goTo"></a>
                </div>
                <div class="vm-link-back-box">
                    <a class="vm-link-back" @click="goTo" data-to="/"></a>
                    <span class="vm-link-back__title">{{user.NAME}}</span>
                </div>

                <div class="vm-hallo__title" v-if="!course.NAME">Мои курсы</div>
                <section class="vm-body vm-body__index1-1" v-if="course.NAME">

                    <div class="vm-container">
                        <div class="vm-two-col">
                            <div class="vm-two-col__item">
                                <div class="vm-title-box">
                                    <i class="vm-icon vm-icon-1"></i>
                                    <h1>{{course.NAME}}</h1>
                                </div>
                                <img v-if="course.PROPERTY_283" class="mobile-only-show" :src="course.PROPERTY_283[0]" alt="img"/>
                                <table class="vm-table vm-table__mini mobile-only-show">
                                    <tr>
                                        <td><i class="vm-icon vm-icon-2"></i></td>
                                        <td>Продолжительность</td>
                                        <td>{{course.PROPERTY_391}}</td>
                                    </tr>
                                    <tr>
                                        <td><i class="vm-icon vm-icon-3"></i></td>
                                        <td>Усилие</td>
                                        <td>{{course.PROPERTY_393}}</td>
                                    </tr>
                                    <!--                            <tr>-->
                                    <!--                                <td><i class="vm-icon vm-icon-4"></i></td>-->
                                    <!--                                <td>Стоимость</td>-->
                                    <!--                                <td>28 000 руб.</td>-->
                                    <!--                            </tr>-->
                                    <tr>
                                        <td><i class="vm-icon vm-icon-5"></i></td>
                                        <td>Предмет</td>
                                        <td>{{course.PROPERTY_395}}</td>
                                    </tr>
                                    <tr>
                                        <td><i class="vm-icon vm-icon-6"></i></td>
                                        <td>Уровень</td>
                                        <td>{{course.PROPERTY_397}}</td>
                                    </tr>
                                    <tr>
                                        <td><i class="vm-icon vm-icon-7"></i></td>
                                        <td>Тип курса</td>
                                        <td>{{course.PROPERTY_399}}</td>
                                    </tr>
                                </table>
                                <div class="vm-static-content">
                                    <h2>Описание курса</h2>
                                    <p v-html="course.PROPERTY_227.TEXT"></p>
                                    <div v-if="course.PROPERTY_385" class="vm-video-box">
                                        <video-player class="video-player-box"
                                                      ref="videoPlayer"
                                                      :options="playerOptions"
                                                      :playsinline="true"
                                        >
                                        </video-player>
                                    </div>
                                </div>
                                <div class="vm-static-content">
                                    <h2>Чему Вы научитесь</h2>
                                    <span v-html="course.PROPERTY_387.TEXT">

                            </span>
                                </div>
                                <div class="vm-static-content">
                                    <h2>Учебный план</h2>
                                    <span v-html="course.PROPERTY_389.TEXT">

                            </span>
                                </div>
                            </div>
                            <div class="vm-two-col__item">
                                <img v-if="course.PROPERTY_283" class="desc-only-show" :src="course.PROPERTY_283[0]" alt="img"/>
                                <table class="vm-table vm-table__mini desc-only-show">
                                    <tr>
                                        <td><i class="vm-icon vm-icon-2"></i></td>
                                        <td>Продолжительность</td>
                                        <td>{{course.PROPERTY_391}}</td>
                                    </tr>
                                    <tr>
                                        <td><i class="vm-icon vm-icon-3"></i></td>
                                        <td>Усилие</td>
                                        <td>{{course.PROPERTY_393}}</td>
                                    </tr>
                                    <!--                            <tr>-->
                                    <!--                                <td><i class="vm-icon vm-icon-4"></i></td>-->
                                    <!--                                <td>Стоимость</td>-->
                                    <!--                                <td>28 000 руб.</td>-->
                                    <!--                            </tr>-->
                                    <tr>
                                        <td><i class="vm-icon vm-icon-5"></i></td>
                                        <td>Предмет</td>
                                        <td>{{course.PROPERTY_395}}</td>
                                    </tr>
                                    <tr>
                                        <td><i class="vm-icon vm-icon-6"></i></td>
                                        <td>Уровень</td>
                                        <td>{{course.PROPERTY_397}}</td>
                                    </tr>
                                    <tr>
                                        <td><i class="vm-icon vm-icon-7"></i></td>
                                        <td>Тип курса</td>
                                        <td>{{course.PROPERTY_399}}</td>
                                    </tr>
                                </table>
                                <div class="vm-static-content">
                                    <h2>Ведущий курса</h2>
                                    <div class="vm-curator-box">
                                        <img v-if="course.PROPERTY_401" class="vm-curator-box__avatar" :src="course.PROPERTY_401[0]" alt="ava"/>
                                        <div class="vm-curator-box__content">
                                            <strong>{{course.PROPERTY_343}}</strong>
                                            <span v-html="course.PROPERTY_403.TEXT">

                                    </span>
                                        </div>
                                    </div>
                                </div>
                                <!--                        <div class="vm-static-content">-->
                                <!--                            <h2>Получите сертификат</h2>-->
                                <!--                            <div class="vm-certificate">-->
                                <!--                                <i class="vm-icon vm-icon-8"></i>-->
                                <!--                                <p>Проверенный сертификат международного образца</p>-->
                                <!--                                <div class="vm-certificate__image">-->
                                <!--                                    <img src="/assets/images/img_3.jpg" alt="cert"/>-->
                                <!--                                    <i class="vm-icon vm-icon-9"></i>-->
                                <!--                                </div>-->
                                <!--                            </div>-->
                                <!--                        </div>-->
                                <div class="vm-static-content mobile-only-show">
                                    <!--                            <h2 class="vm-title-with-price">Стоимость курса <span>28 000 руб.</span></h2>-->

                                </div>
                            </div>
                        </div>

                    </div>

                </section>

                <div class="vm-two-col" id="courses">
                    <div v-for="course in courses" v-bind:key="course.ID" class="vm-two-col__item" v-show="course.PROPERTY_227.TEXT">
                        <div class="vm-course-box">
                            <div class="vm-course__header vm-course__header--1" :style="'background: linear-gradient(90deg, '+course.PROPERTY_347+' 35%, '+course.PROPERTY_349+' 100%);'">
                                <div class="vm-course__logo"><img v-if="course.PROPERTY_417"  :src="course.PROPERTY_417[0]" alt="logo"/></div>
                                <span class="vm-course__name">{{course.NAME}}</span>
                            </div>
                            <div class="vm-course__content">
                                <p v-html="course.PROPERTY_227.TEXT"> </p>
                                <div class="vm-course__metrics">
                                    <div><i class="vm-icon vm-icon-3"></i>Продолжительность: {{course.PROPERTY_345}} лекций</div>
                                    <div><i class="vm-icon vm-icon-7"></i>Курс читает: {{course.PROPERTY_343}} </div>
                                </div>
                            </div>
                            <div class="vm-course__footer">
                                <div class="vm-course__price vm-course__price--free">
                                    <div v-if="!course.PROPERTY_261"><span>0</span><i class="vm-icon vm-icon-27"></i></div>
                                    <div v-if="course.PROPERTY_261"><i style="font-size: 27px;margin-bottom: 0px;" class="vm-icon vm-icon-27"></i></div>
                                </div>
                                <div class="vm-course__link">
                                    <a @click="goTo" :data-to="(course.GROUP)? '/learning/under/'+course.ID+'/':'/course/'+course.ID+'/'">перейти к курсу</a>
                                </div>
                                <div class="vm-course__status vm-course__status--active">
                                    <span v-if="((user.PROPERTY_303 && user.PROPERTY_303.indexOf( course.ID ) == -1) || !user.PROPERTY_303)"><i  class="vm-icon vm-icon-25"></i></span>
                                    <span v-if="((user.PROPERTY_303 && user.PROPERTY_303.indexOf( course.ID ) != -1)) && !(user.PROPERTY_305 && user.PROPERTY_305.indexOf( course.ID ) != -1)"><i  class="vm-icon vm-icon-11"></i></span>
                                    <span v-if="user.PROPERTY_305 && user.PROPERTY_305.indexOf( course.ID ) != -1"><i class="vm-icon vm-icon-28"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Navigation active="learn"></Navigation>

            </div>

        </section>
        <Footer></Footer>
    </div>
</template>

<script>
    import config from '../config';
    import Header from './header.vue';
    import Footer from './footer.vue';
    import Navigation from './navigation.vue';
    export default {
        name: "Learning",
        components: {
            Header,
            Footer,
            Navigation
        },
        data() {
            return {
                show: false,
                url: config.backend_url,
                user: this.$store.getters.userInfo,
                course: {},
                courses: []
            }
        },
        methods: {
            goTo(e) {
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
                this.getCourses();
            },

            getCourses() {
                let self = this;
                this.show = false;
                let admin = (this.user.PROPERTY_433)? this.user.PROPERTY_433 : "211";
                let courseUp = (this.$route.params.course)? '&course='+this.$route.params.course : '';
                let boughtCourse = (this.user.PROPERTY_460)? this.user.PROPERTY_460.toString() : null;
                this.axios.get(this.url + "BX24/getCourses/?admin="+admin+courseUp+"&boughtCourse="+boughtCourse).then((response) => {
                    self.courses = response.data;
                    if (self.$route.params.course) {
                        self.axios.get(self.url + "BX24/getCourses/?id=" + self.$route.params.course + "&admin=" + admin).then((response) => {
                            self.course = response.data[0];
                            if (response.data[0].PROPERTY_385) {
                                self.$set(self.playerOptions.sources, 0, {
                                    type: "video/mp4",
                                    src: response.data[0].PROPERTY_385[0],
                                });
                            }
                            self.show = true;
                        })
                    }
                    else {
                        self.show = true;
                    }
                })
            }
        },
        created() {
        },
        mounted() {
            let self = this;
            this.axios.get(this.url + "BX24/getNPV/?id=" + this.user.PROPERTY_249).then((response) => {
                if (response.data) {
                    self.$store.dispatch("SET_USERINFO", response.data);
                    self.user = self.$store.getters.userInfo;
                    // setTimeout(() => self.getCourse(), 1000);

                } else {
                    alert("Данного пользователя нет в системе");
                }
            });
            this.getCourses();
        }
    }
</script>

<style scoped>

</style>