<template>
    <div class="body">
        <Header></Header>
<!--        <hexagon v-if="!showAll" style="position: absolute;top: 50vh;left: 50vw;z-index: 999;"></hexagon>-->
        <section class="vm-body vm-body__index1">
            <div class="vm-container">

                <div class="vm-link-back-box">
                    <a class="vm-link-back" href="index.html"></a>
                    <span class="vm-link-back__title">{{user.NAME}}</span>
                </div>

                <div class="vm-home-work__header">
                    <div class="vm-home-work__header-item">
                        <div class="vm-home-work__header-info"><i class="vm-icon vm-icon-1"></i>Курс: {{courses.NAME}}</div>
                        <div class="vm-home-work__header-info"><i class="vm-icon vm-icon-21"></i>{{courseContent.NAME}}
                        </div>
                        <div class="vm-home-work__header-info"><i class="vm-icon vm-icon-7"></i>Курс читает<br/>{{courses.PROPERTY_343}}
                        </div>
                    </div>
                    <div class="vm-home-work__header-item">
                        <div class="vm-home-work__header-info" v-if="$route.name != 'setmark'"><i class="vm-icon vm-icon-7" ></i>Ваш наставник<br/>{{master.NAME}}
                        </div>
                        <div class="vm-home-work__header-info" v-if="$route.name == 'setmark'" ><i class="vm-icon vm-icon-7"></i>Ваш ученик<br/>{{ht[0].NAME}}
                        </div>
                    </div>
                </div>

                <div class="vm-hallo__title">Домашнее задание к лекции {{courseContent.SORT}}:</div>

                <div class="vm-one-col">
                    <div class="vm-two-col__item">
                        <div class="vm-home-work__sub-title">
<!--                            <i class="vm-icon vm-icon-22"></i>-->
                            <span v-html="courseContent.PROPERTY_289.TEXT"></span>
                        </div>
                        <div class="vm-home-work__form-title" v-if="!ht[0]">Введите ваши ответы в поле ниже:</div>
                        <div class="vm-home-work__form-title" v-if="ht[0]">Ответы:</div>
                        <textarea @change="inputAnswer" v-if="!ht[0]" class="vm-home-work__textfeild" v-model="answer.textAnswer"></textarea>
                        <p v-if="ht[0]" v-html="ht[0].PROPERTY_315.TEXT">

                        </p>
                        <div v-if="!ht[0]" class="vm-home-work__btns">
<!--                            <a class="vm-btn vm-btn-file" href="#"><img src="/assets/images/file.svg" alt="file"/>прикрепить-->
<!--                                материалы</a>-->
                            <a v-if="!sended" class="vm-btn" @click="sendAnswer">отправить</a>
                        </div>
<!--                        <div v-if="!ht[0]" class="vm-home-work__file-info">jpg, jpeg, png, gif, doc, docx, rtf, pdf, максимальный-->
<!--                            размер не должен превышать 5 MB-->
<!--                        </div>-->
                        <div class="vm-lession__docs-list">
                        </div>
                    </div>
                    <div class="vm-two-col__item">
                        <div class="vm-home-work__form-title" v-if="ht[0]">комментарий наставника:</div>
                        <p v-if="$route.name != 'setmark' && !ht[1] && ht[0]">Спасибо, ваши ответы приняты. Ожидайте проверки домашнего задания.</p>
                        <textarea @change="inputAnswer" v-if="$route.name == 'setmark' && !ht[1]" class="vm-home-work__textfeild" v-model="answer.textCurator"></textarea>
                        <p v-if="ht[1]" v-html="ht[1].PROPERTY_317.TEXT">

                        </p>
                        <div v-if="$route.name == 'setmark' && !ht[1]" class="vm-home-work__btns">
                            <div class="vm-home-work__select">
                                <label class="vm-home-work__select-label" for="selId">Оценка</label>
                                <select v-model="mark" id="selId">
                                    <option :value="null">Поставьте оценку</option>
                                    <option value="Отлично">Отлично</option>
                                    <option value="Хорошо">Хорошо</option>
                                    <option value="Удовлетворительно">Удовлетворительно</option>
                                </select>
                            </div>
                            <a v-if="!sended" class="vm-btn" @click="sendAnswer">отправить</a>
                        </div>
                        <a class="vm-btn"  @click="back">Назад</a>
                    </div>
                </div>
                <Navigation active="learn"></Navigation>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script>
    import config from '../config';
    import Header from './header.vue';
    import Footer from './footer.vue';
    import Navigation from './navigation.vue';
    // import {Hexagon} from 'vue-loading-spinner'

    export default {
        name: "Hometask",
        components: {
            // Hexagon,
            Header,
            Footer,
            Navigation
        },
        data() {
            return {
                url: config.backend_url,
                user: this.$store.getters.userInfo,
                courseContent: [],
                courses: [],
                ht: [],
                answer: {
                    textAnswer: null,
                    textCurator: null,
                },
                sended: false,
                mark: null,
                master: {},
                showAll:false,
                changedAnswer: false
            }
        },
        methods: {
            inputAnswer(){
                this.changedAnswer = true;
            },
            back(){
                window.history.back()
            },
            goTo(e) {
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            },
            getCourses() {
                let self = this;
                let admin = (this.user.PROPERTY_433)? this.user.PROPERTY_433 : "";
                this.axios.get(this.url + "BX24/getCourses/?id="+this.$route.params.id+"&type=noFile"+"&admin="+admin).then((response) => {
                    self.courses = response.data[0];
                })
            },
            getCourse() {
                let self = this;
                this.axios.get(this.url + "BX24/getCourse/?currentId=" + this.$route.params.lect+"&type=noFile").then((response) => {
                    self.courseContent = response.data[0];
                    self.answer.textAnswer = self.courseContent.PROPERTY_425;
                });
            },
            getMaster(){
                let self = this;
                this.axios.get(this.url + "BX24/getNPV/?idFace="+this.user.PROPERTY_273).then((response) => {
                    if (response.data) {
                        self.master = response.data;
                    }
                })
            },
            sendAnswer(){
                if (this.changedAnswer  && !this.sended) {
                    if ((this.$route.name == "setmark" && this.answer.textCurator && this.mark) || this.$route.name == "hometask") {
                        this.sended = true;
                        let self = this;
                        let data = {
                            prop_NAME: this.user.NAME,
                            prop_PROPERTY_311: this.$route.params.id, //курс
                            prop_PROPERTY_313: this.$route.params.lect, // лекция
                            prop_PROPERTY_315: this.answer.textAnswer, // ответ ученика
                            prop_PROPERTY_317: this.answer.textCurator, // ответ куратора
                            prop_PROPERTY_383: (this.$route.name == "setmark") ? this.$route.params.student : this.user.ID, // ид ученика
                            prop_PROPERTY_327: (this.answer.textCurator) ? "171" : "137", // тип действия
                        }
                        this.axios.post(this.url + "BX24/setHomeTask/", data).then(() => {
                            self.getHt(false);
                        });
                        if (this.answer.textCurator) {
                            this.axios.post(this.url + "BX24/setCheckedHt/?id=" + this.ht[0].ID + "&mark=" + this.mark)
                        }
                    }
                    else {
                        alert("Поставьте оценку и введите свой комментарий.")
                    }
                }
                else {
                    alert("Вы не ввели свой ответ или комментарий");
                }
            },
            getHt(open){
                let self = this;
                let id = this.user.ID;
                if (this.$route.name == "setmark"){
                    id = this.$route.params.student;
                }
                this.axios.get(this.url + "BX24/getHt/?id=" + this.$route.params.lect+'&student='+id).then((response) => {
                    self.ht = response.data;
                    if (self.ht[1] && open){
                        self.axios.post(self.url + "BX24/setWatchedHt/?id="+self.ht[0].ID)
                    }
                });
            }
        },
        created() {
            this.getCourse();
            this.getCourses();
            this.getMaster();
            this.getHt(true);
            // setTimeout(() => this.showAll=true, 2000);
        },
        mounted() {
            // this.getCourse();


        }
    }
</script>

<style scoped>

</style>