<template>
    <div class="vm-quick-nav">
        <a title="Мои курсы" :class="'vm-quick-nav__item vm-quick-nav__item-1'+(($attrs.active == 'learn')? '--active':'')" @click="goTo" data-to="/learning"><i @click="goTo" data-to="/learning" class="vm-icon vm-icon-16"></i></a>
        <a title="Мой дневник" :class="'vm-quick-nav__item vm-quick-nav__item-2'+(($attrs.active == 'diary')? '--active':'')" @click="goTo" data-to="/diary"><i @click="goTo" data-to="/diary" class="vm-icon vm-icon-17"></i></a>
<!--        <a class="vm-quick-nav__item vm-quick-nav__item-3" href="index3.html"><i class="vm-icon vm-icon-18"></i></a>-->
        <a title="Мои ученики" v-if="students.length > 0" :class="'vm-quick-nav__item vm-quick-nav__item-4'+(($attrs.active == 'students')? '--active':'')" @click="goTo" data-to="/students"><i @click="goTo" data-to="/students" class="vm-icon vm-icon-19"></i></a>
    </div>
</template>

<script>
    import config from '../config';
    export default {
        name: "navigation",
        data() {
            return {
                url: config.backend_url,
                user: this.$store.getters.userInfo,
                students: []
            }
        },
        methods: {
            goTo(e) {
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            },
        },
        created() {
            let self = this;
            this.axios.get(this.url + "BX24/getStudents/?id="+this.user.ID).then((response) => {
                self.students = response.data;
            })
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>