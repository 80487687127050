<template>
    <div class="body">
        <Header></Header>
        <hexagon v-if="autoSign" style="position: absolute;top: 50vh;left: 50vw;z-index: 999;"></hexagon>
        <div v-if="!autoSign" class="signin">
            <div class="signin-header">Введите предоставленный ID</div>
            <div class="signin-input">
                <input type="text" v-model="mail" v-on:keyup.enter="signIn" placeholder="ID"></div>
            <div class="signin-button">
                <button @click="signIn">Войти</button>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import config from '../config';
    import Header from './header.vue';
    import Footer from './footer.vue';
    import {Hexagon} from 'vue-loading-spinner'
    export default {
        name: "Signin",
        components: {
            Header,
            Footer,
            Hexagon
        },
        data() {
            return {
                url: config.backend_url,
                mail: null,
                autoSign: false
            }
        },
        methods: {
            signIn() {
                let self = this;
                this.axios.get(this.url + "BX24/getNPV/?id="+this.mail).then((response) => {
                    if (response.data) {
                        // window.console.log(response.data[0]);
                        self.$store.dispatch("SET_USERINFO", response.data);
                        self.$store.dispatch("SET_USERID", self.mail);
                        // window.console.log(self.$store.getters.userInfo);
                        self.$router.push("/");
                    } else {
                        alert("Данного пользователя нет в системе");
                    }
                })
            }
        },
        created() {
            if (this.$route.params.id){
                this.autoSign = true;
            }
        },
        mounted() {
            // window.console.log();
        }
    }
</script>

<style scoped>

</style>