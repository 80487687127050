<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import config from './config';
  export default {
    data() {
      return {
        url: config.backend_url,
      }
    },
    created() {
      let self = this;
      if (!this.$store.getters.userId && !this.$route.params.id){
        this.$router.push("/signin/");
      }
      else if (this.$route.params.secret) {
        let data = this.$route.params;
        this.axios.post(this.url + "BX24/signup/", data).then((response) => {
          if (response.data.result) {
            self.$store.dispatch("SET_USERINFO", response.data);
            self.$store.dispatch("SET_USERID", response.data.ID);
            window.console.log(self.$store.getters.userInfo);
            self.$router.push("/");
          } else {
            alert("Данного пользователя нет в системе");
          }
        });
      }
    },
  }
</script>

<style>
  @import '/assets/styles/style.css';
</style>