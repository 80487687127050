import Vue from 'vue'
import Vuex from 'vuex'
import cookie from 'vue-cookies'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: cookie.get("userId"),
    userName: null,
    userInfo: cookie.get("userInfo")
  },
  mutations: {
    SET_USERID: (state, payload) => {
      state.userId = payload;
    },
    SET_USERNAME: (state, payload) => {
      state.userName = payload;
    },
    SET_USERINFO: (state, payload) => {
      state.userInfo = payload;
    },
  },
  actions: {
    SET_USERID (context, payload) {
      cookie.set("userId", payload, 36000000000);
      context.commit('SET_USERID', payload);
    },
    SET_USERNAME (context, payload) {
      context.commit('SET_USERNAME', payload);
    },
    SET_USERINFO (context, payload) {
      context.commit('SET_USERINFO', payload);
      cookie.set("userInfo", JSON.stringify(payload), 36000000000);
    },
  },
  getters: {
    userId: state => {
      return state.userId
    },
    userName: state => {
      return state.userName
    },
    userInfo: state => {
      return state.userInfo
    }
  }
})
