<template>
    <div class="body">
        <Header></Header>
        <section class="vm-body vm-body__index1">

            <div class="vm-container">

                <div class="vm-link-back-box">
                    <a class="vm-link-back" href="index.html"></a>
                    <span class="vm-link-back__title">{{user.NAME}}</span>
                </div>

                <div class="vm-hallo__title">МОИ УЧЕНИКИ</div>

                <div class="vm-shoulder">
                    <div class="vm-shoulder__item">
                        <div class="vm-shoulder__select">
                            <label>Выберите ученика</label>
                            <select @change="getHts" v-model="selectedStudent">
                                <option :value="null">Все</option>
                                <option v-for="(student, k) in students" v-bind:key="k" :value="k">{{student.NAME}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="vm-shoulder__table-info">
                    <div>&nbsp;</div>
                    <div>
                        <a :style="(showAll)? 'font-weight: bold': ''" @click="toogleChecked(true)">Все</a><br/>
                        <a :style="(!showAll)? 'font-weight: bold': ''" @click="toogleChecked(false)">Непроверенные</a>
                    </div>
                </div>
                <div v-if="!hts[0]">Выбранный ученик еще не сделал ни одного задания.</div>
                <table class="vm-shoulder__table vm-shoulder__table-2">
                    <thead>
                    <tr>
                        <th>Курс </th>
                        <th>Лекция</th>
                        <th>Ученик</th>
                        <th>Дата</th>
                        <th><i class="vm-icon vm-icon-20"></i></th>
                        <th>Задание</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(ht, k) in hts" :class="'active'" v-bind:key="k" v-show="ht.PROPERTY_315 && (ht.PROPERTY_321 == checked || showAll )">
                        <td :style="(ht.PROPERTY_321 == '131')? '' : 'font-weight: bold;'">{{courseName(ht.PROPERTY_311)}}</td>
                        <td :style="(ht.PROPERTY_321 == '131')? '' : 'font-weight: bold;'">{{lectNames[ht.PROPERTY_313].NAME}} </td>
                        <td :style="(ht.PROPERTY_321 == '131')? '' : 'font-weight: bold;'">{{ht.NAME}}</td>
                        <td :style="(ht.PROPERTY_321 == '131')? '' : 'font-weight: bold;'">{{ht.PROPERTY_325}}</td>
                        <td :style="(ht.PROPERTY_321 == '131')? '' : 'font-weight: bold;'">{{ht.PROPERTY_415}}</td>
                        <td :style="(ht.PROPERTY_321 == '131')? '' : 'font-weight: bold;'">
                            <a @click="goTo" :data-to="'/setmark/'+ht.PROPERTY_311+'/'+ht.PROPERTY_313+'/'+ht.PROPERTY_383+'/'">Открыть</a>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Navigation active="students"></Navigation>

            </div>

        </section>
        <Footer></Footer>
    </div>
</template>

<script>
    import config from '../config';
    import Header from './header.vue';
    import Footer from './footer.vue';
    import Navigation from './navigation.vue';
    export default {
        name: "Students",
        components: {
            Header,
            Footer,
            Navigation
        },
        data() {
            return {
                url: config.backend_url,
                user: this.$store.getters.userInfo,
                students: [],
                selectedStudent: null,
                hts: [],
                courses: [],
                lectNames: {},
                checked: undefined,
                showAll: true
            }
        },
        methods: {
            toogleChecked(val){
                this.showAll = val;
            },
            goTo(e) {
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            },
            getStudents() {
                let self = this;
                this.axios.get(this.url + "BX24/getStudents/?id="+this.user.ID).then((response) => {
                    self.students = response.data;
                    setTimeout(() =>self.getHts(), 500);
                })
            },
            getHts(){
                let self = this;
                if (this.selectedStudent != null && this.selectedStudent != "null") {
                    this.axios.get(this.url + "BX24/getStudentHT/?id=" + this.students[this.selectedStudent].ID).then((response) => {
                        self.hts = response.data;
                        this.$cookies.set("studentSelected", self.selectedStudent, 36000000000);
                    })
                }
                else {
                    this.students.map(function (student) {
                        self.axios.get(self.url + "BX24/getStudentHT/?id=" + student.ID+"&onlyAnswers=Y").then((response) => {
                            self.hts = self.hts.concat(response.data);
                            self.$cookies.set("studentSelected", self.selectedStudent, 36000000000);
                        })
                    })
                }
            },
            isActive(lect) {
                let k = 0;
                for (let i = 0; i < this.hts.length; i++) {
                    if (this.hts[i].PROPERTY_313 == lect) {
                        k++;
                        if (k > 1) {
                            return false;
                        }
                    }
                }
                return true;
            },
            getCourses() {
                let self = this;
                this.axios.get(this.url + "BX24/getCourses/").then((response) => {
                    self.courses = response.data;
                })
            },
            courseName(course){
                let courseName = this.courses.find(cours => cours.ID === course).NAME;
                return courseName;
            },
            getLects(){
                let self = this;
                this.axios.get(this.url + "BX24/getLect/").then((response) => {
                    self.lectNames = response.data;
                });
            }
        },
        created() {
           this.getStudents();
           this.getCourses();
           this.getLects();
           this.selectedStudent = (this.$cookies.get("studentSelected"))? this.$cookies.get("studentSelected") : null;
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>