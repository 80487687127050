<template>
    <div class="body">
        <Header></Header>
        <section class="vm-body vm-body__index1-1">

            <div class="vm-container">

                <div class="vm-link-back-box">
                    <a class="vm-link-back" data-to="learning" @click="goTo"></a>
                </div>
                <div class="vm-two-col" v-if="!start && !courses.PROPERTY_261">
                    <!--                                <a class="vm-btn" href="#">перейти к оплате</a>-->
                    <a class="vm-btn" :data-to="'/learning/'+$route.params.id" @click="goTo" data-type="start">НАЧАТЬ
                        ОБУЧЕНИЕ</a>
                </div>
                <div v-if="start && !finished" class="vm-two-col">
                    <div class="vm-two-col__item">
                    </div>
                    <div class="vm-two-col__item">
                        <a class="vm-btn" :data-to="'/learning/'+$route.params.id" @click="goTo">Продолжить обучение</a>
                    </div>
                </div>
                <div v-if="finished" class="vm-two-col">
                    <div class="vm-two-col__item">
                    </div>
                    <div class="vm-two-col__item">
                        <a class="vm-btn" :data-to="'/learning/'+$route.params.id" @click="goTo">Перейти к курсу</a>
                    </div>
                </div>
                <div class="vm-two-col">
                    <div class="vm-two-col__item">
                        <div class="vm-title-box">
                            <i class="vm-icon vm-icon-1"></i>
                            <h1>{{courses.NAME}}</h1>
                        </div>
                        <img v-if="courses.PROPERTY_283" class="mobile-only-show" :src="courses.PROPERTY_283[0]" alt="img"/>
                        <table class="vm-table vm-table__mini mobile-only-show">
                            <tr>
                                <td><i class="vm-icon vm-icon-2"></i></td>
                                <td>Продолжительность</td>
                                <td>{{courses.PROPERTY_391}}</td>
                            </tr>
                            <tr>
                                <td><i class="vm-icon vm-icon-3"></i></td>
                                <td>Усилие</td>
                                <td>{{courses.PROPERTY_393}}</td>
                            </tr>
                            <!--                            <tr>-->
                            <!--                                <td><i class="vm-icon vm-icon-4"></i></td>-->
                            <!--                                <td>Стоимость</td>-->
                            <!--                                <td>28 000 руб.</td>-->
                            <!--                            </tr>-->
                            <tr>
                                <td><i class="vm-icon vm-icon-5"></i></td>
                                <td>Предмет</td>
                                <td>{{courses.PROPERTY_395}}</td>
                            </tr>
                            <tr>
                                <td><i class="vm-icon vm-icon-6"></i></td>
                                <td>Уровень</td>
                                <td>{{courses.PROPERTY_397}}</td>
                            </tr>
                            <tr>
                                <td><i class="vm-icon vm-icon-7"></i></td>
                                <td>Тип курса</td>
                                <td>{{courses.PROPERTY_399}}</td>
                            </tr>
                        </table>
                        <div class="vm-static-content">
                            <h2>Описание курса</h2>
                            <p v-if="courses.PROPERTY_227" v-html="courses.PROPERTY_227.TEXT"></p>
                            <div v-if="courses.PROPERTY_385" class="vm-video-box">
                                <video-player class="video-player-box"
                                              ref="videoPlayer"
                                              :options="playerOptions"
                                              :playsinline="true"
                                >
                                </video-player>
                            </div>
                        </div>
                        <div v-if="courses.PROPERTY_387" class="vm-static-content">
                            <h2>Чему Вы научитесь</h2>
                            <span v-html="courses.PROPERTY_387.TEXT">

                            </span>
                        </div>
                        <div v-if="courses.PROPERTY_389" class="vm-static-content">
                            <h2>Учебный план</h2>
                            <span v-html="courses.PROPERTY_389.TEXT">

                            </span>
                        </div>
                        <div class="vm-static-content desc-only-show">
                            <h2 class="vm-title-with-price" v-if="!start && courses.PROPERTY_261">Стоимость курса <span>{{courses.PROPERTY_261}} руб.</span>
                            </h2>
                            <div class="vm-two-col">
                                <a class="vm-btn" href="#" v-if="!start && courses.PROPERTY_261">перейти к оплате</a>
                                <a class="vm-btn" v-if="!start && !courses.PROPERTY_261"
                                   :data-to="'/learning/'+$route.params.id" @click="goTo"
                                   data-type="start">НАЧАТЬ
                                    ОБУЧЕНИЕ</a>
                            </div>
                            <div class="vm-one-col" v-if="start && !finished">
                                <!--                                <a class="vm-btn" href="#">перейти к оплате</a>-->
                                <a class="vm-btn" :data-to="'/learning/'+$route.params.id" @click="goTo"
                                >Продолжить обучение</a>
                            </div>
                        </div>
                    </div>
                    <div class="vm-two-col__item">
                        <img v-if="courses.PROPERTY_283" class="desc-only-show" :src="courses.PROPERTY_283[0]" alt="img"/>
                        <table class="vm-table vm-table__mini desc-only-show">
                            <tr>
                                <td><i class="vm-icon vm-icon-2"></i></td>
                                <td>Продолжительность</td>
                                <td>{{courses.PROPERTY_391}}</td>
                            </tr>
                            <tr>
                                <td><i class="vm-icon vm-icon-3"></i></td>
                                <td>Усилие</td>
                                <td>{{courses.PROPERTY_393}}</td>
                            </tr>
                            <!--                            <tr>-->
                            <!--                                <td><i class="vm-icon vm-icon-4"></i></td>-->
                            <!--                                <td>Стоимость</td>-->
                            <!--                                <td>28 000 руб.</td>-->
                            <!--                            </tr>-->
                            <tr>
                                <td><i class="vm-icon vm-icon-5"></i></td>
                                <td>Предмет</td>
                                <td>{{courses.PROPERTY_395}}</td>
                            </tr>
                            <tr>
                                <td><i class="vm-icon vm-icon-6"></i></td>
                                <td>Уровень</td>
                                <td>{{courses.PROPERTY_397}}</td>
                            </tr>
                            <tr>
                                <td><i class="vm-icon vm-icon-7"></i></td>
                                <td>Тип курса</td>
                                <td>{{courses.PROPERTY_399}}</td>
                            </tr>
                        </table>
                        <div class="vm-static-content">
                            <h2>Ведущий курса</h2>
                            <div class="vm-curator-box">
                                <img v-if="courses.PROPERTY_401" class="vm-curator-box__avatar" :src="courses.PROPERTY_401[0]" alt="ava"/>
                                <div class="vm-curator-box__content">
                                    <strong>{{courses.PROPERTY_343}}</strong>
                                    <span v-if="courses.PROPERTY_403" v-html="courses.PROPERTY_403.TEXT">

                                    </span>
                                </div>
                            </div>
                        </div>
                        <!--                        <div class="vm-static-content">-->
                        <!--                            <h2>Получите сертификат</h2>-->
                        <!--                            <div class="vm-certificate">-->
                        <!--                                <i class="vm-icon vm-icon-8"></i>-->
                        <!--                                <p>Проверенный сертификат международного образца</p>-->
                        <!--                                <div class="vm-certificate__image">-->
                        <!--                                    <img src="/assets/images/img_3.jpg" alt="cert"/>-->
                        <!--                                    <i class="vm-icon vm-icon-9"></i>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="vm-static-content mobile-only-show">
                            <!--                            <h2 class="vm-title-with-price">Стоимость курса <span>28 000 руб.</span></h2>-->

                            <div class="vm-two-col" v-if="!start && !courses.PROPERTY_261">
                                <!--                                <a class="vm-btn" href="#">перейти к оплате</a>-->
                                <a class="vm-btn" :data-to="'/learning/'+$route.params.id" @click="goTo"
                                   data-type="start">НАЧАТЬ
                                    ОБУЧЕНИЕ</a>
                            </div>
                            <div class="vm-two-col" v-if="!start && courses.PROPERTY_261">
                                <!--                                <a class="vm-btn" href="#">перейти к оплате</a>-->
                                <a class="vm-btn"
                                   data-type="start">Оплатить {{courses.PROPERTY_261}} руб.</a>
                            </div>
                            <div class="vm-two-col" v-if="start && !finished">
                                <a class="vm-btn" :data-to="'/learning/'+$route.params.id" @click="goTo">Продолжить
                                    обучение</a>
                            </div>
                        </div>
                    </div>
                </div>

                <Navigation active="learn"></Navigation>

            </div>

        </section>
        <Footer></Footer>
    </div>
</template>

<script>
    import config from '../config';
    import Header from './header.vue';
    import Footer from './footer.vue';
    import Navigation from './navigation.vue';

    export default {
        name: "Learning",
        components: {
            Header,
            Footer,
            Navigation
        },
        data() {
            return {
                url: config.backend_url,
                user: this.$store.getters.userInfo,
                courses: [],
                start: false,
                finished: false,
                currentLect: null,
                playerOptions: {
                    // videojs options
                    muted: false,
                    language: 'en',
                    playbackRates: [0.7, 1.0, 1.5, 2.0],
                    sources: [
                        // {
                        //     type: "video/mp4",
                        //     src: ""
                        // }
                    ],
                    poster: "",
                },
            }
        },
        methods: {
            goTo(e) {
                let to = e.srcElement.dataset.to;
                let start = e.srcElement.dataset.type;
                window.console.log(to);
                if (start == 'start') {
                    this.startCourse();
                }
                this.$router.push(to);
            },
            getCourses() {
                let self = this;
                let admin = (this.user.PROPERTY_433) ? this.user.PROPERTY_433 : "";
                this.axios.get(this.url + "BX24/getCourses/?id=" + this.$route.params.id + "&admin=" + admin).then((response) => {
                    self.courses = response.data[0];
                    if (response.data[0].PROPERTY_385) {
                        self.$set(self.playerOptions.sources, 0, {
                            type: "video/mp4",
                            src: response.data[0].PROPERTY_385[0],
                        });
                    }
                    self.checkProgress();
                })
            },
            checkProgress() {
                if (this.user.PROPERTY_303) {
                    let idCourse = this.user.PROPERTY_303.indexOf(this.$route.params.id);
                    if (idCourse != -1) {
                        this.start = true;
                        this.currentLect = this.user.PROPERTY_259[idCourse];
                    }
                }
                if (this.user.PROPERTY_305) {
                    let idCourse = this.user.PROPERTY_305.indexOf(this.$route.params.id);
                    if (idCourse != -1) {
                        this.finished = true;
                    }
                }
            },
            startCourse() {
                this.axios.get(this.url + "BX24/setUserInfo/?id=" + this.user.PROPERTY_249 + "&prop_PROPERTY_303=" + this.$route.params.id + "&prop_PROPERTY_259=0");
            }
        },
        created() {
            this.getCourses()
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>