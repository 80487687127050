<template>
    <div class="vm-body vm-body__index">

        <Header></Header>
        <section class="vm-body vm-body__index">

            <div class="vm-container">

                <div class="vm-hallo__title">Здравствуйте, {{user.NAME}}!</div>

                <div class="vm-hallo__box">
                    <a @click="goTo" data-to="learning" class="vm-hallo__item vm-hallo__item-1">
                        <img @click="goTo" data-to="learning" src="/assets/images/img_4.jpg" alt="img"/>
                        <span @click="goTo" data-to="learning" class="vm-hallo__item-title">МОИ КУРСЫ</span>
                    </a>
<!--                    <a @click="goTo" data-to="learning" class="vm-hallo__item vm-hallo__item-2">-->
<!--                        <img src="/assets/images/img_5.jpg" alt="img"/>-->
<!--                        <span class="vm-hallo__item-title">МОИ НАСТАВНИКИ</span>-->
<!--                    </a>-->
                    <a @click="goTo" data-to="diary" class="vm-hallo__item vm-hallo__item-3">
                        <img @click="goTo" data-to="diary" src="/assets/images/img_6.jpg" alt="img"/>
                        <span @click="goTo" data-to="diary" class="vm-hallo__item-title">МОЙ ЭЛЕКТРОННЫЙ ДНЕВНИК</span>
                    </a>
                    <a v-if="students.length > 0" @click="goTo" data-to="students" class="vm-hallo__item vm-hallo__item-4">
                        <img @click="goTo" data-to="students" src="/assets/images/img_7.jpg" alt="img"/>
                        <span @click="goTo" data-to="students" class="vm-hallo__item-title">МОИ УЧЕНИКИ</span>
                    </a>
                </div>

                <Navigation></Navigation>

            </div>

        </section>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from './header.vue';
    import Footer from './footer.vue';
    import Navigation from './navigation.vue';
    import config from '../config';
    export default {
        components: {
            Header,
            Footer,
            Navigation
        },
        data() {
            return {
                url: config.backend_url,
                user: this.$store.getters.userInfo,
                students: []
            }
        },
        methods: {
            goTo(e) {
                let to = e.srcElement.dataset.to;
                this.$router.push(to);
            }
        },
        created() {
            let self = this;
            this.axios.get(this.url + "BX24/getStudents/?id="+this.user.ID).then((response) => {
                self.students = response.data;
            })
        },
        mounted() {
        }
    }
</script>